/* index.css */

@import url("https://fonts.googleapis.com/css2?family=Lato");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* FIX THEME COLORS */
@layer base {
  :root {
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted-foreground: 0 0% 60%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --input: 0 0% 89.8%;
    --ring: 0 3.9% 80%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --border: 0 0% 89.8%;
    --muted: 0 0% 90.1%;
    --primary-foreground: #57dfb0;
    --primary-foreground: #57dfaf3b;
    --background: #f9f9f9;
    --primary: #57dfb0;
    --primary-darker: #3a9a78;
    --accent: 0 0% 85.1%;
    --radius: 0.5rem;
  }
}

@layer base {
  body {
    @apply bg-background text-foreground;
  }
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  font-family: "Lato", sans-serif;
  background: #f9f9f9;

  ;
  background-size: cover;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --bg-color: #f9f9f9;
  --theme-color: #57dfb0;
  --theme-dark: #55cca3;
  --nav-size: 60px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* navbar.css */
.navbar {
  height: var(--nav-size);
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  z-index: 49;
}

.navbar ul {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-logo {
  margin-right: auto;
  border: none;
}

.logo-img {
  max-height: calc(var(--nav-size) * 0.45);
}

.email-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--nav-size) * 0.6);
  width: calc(var(--nav-size) * 0.6);
  border: none;
  border-radius: 50%;
  transition: 0.3s all ease;
  background-color: white;
  cursor: pointer;
}

.email-button:hover {
  filter: brightness(0.9);
}

.email-icon {
  font-size: calc(var(--nav-size) * 0.35);
}

.user-dropdown {
  height: calc(var(--nav-size) * 0.8);
  min-width: 60px;
  display: flex;
  align-items: center;
  gap: 0.5em;
  border-color: black;
  border: 3px;
  border-radius: 50px;
  padding: 0.5em;
  transition: 0.3s all ease;
  background-color: white;
  cursor: pointer;
}

.user-dropdown:hover {
  filter: brightness(0.9);
}

.user-img {
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: calc(var(--nav-size) * 0.6);
  width: calc(var(--nav-size) * 0.6);
  border-radius: 50%;
}

.user-img img {
  height: 100%;
  width: 120%;
  object-fit: cover;
}

.user-name {
  font-family: inherit;
  font-size: 1rem;
}

.photo {
  width: 160px;
  height: 110px;
}

.go-icon {
  height: 60px;
  width: 50px;
  color: #57dfb0;
  margin-left: 130px;
  place-self: end;
}